.Web2Print-Order-Documentation-Sheet {
    background-color: #ffffff !important;

    // padding cant be defined on the whole page
    // We have to define it on every single element...

    .padding-x {
        @apply px-14;
    }

    .padding-top {
        @apply pt-14;
    }

    .padding-bottom {
        @apply pb-14;
    }

    // default tailwind color classes don't work -> they produce rgb()-colors
    .text-white {
        color: white !important;
    }

    .text-black {
        color: #000000 !important;
    }

    .text-blue {
        color: #009bb9 !important;
    }

    .text-orange {
        color: #F6AA34 !important;
    }

    .bg-blue {
        background-color: #009bb9 !important;
    }

    .bg-lightblue {
        background-color: #e3faff !important;
    }

    .print-wysiwyg {
        a,
        strong {
            @apply font-bold;
        }
    }
}
