html {
    font-size: 16px;
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
    
    @media (min-width: theme("screens.xl")) {
        scroll-padding-top: 170px;
    }
}

body {
    @apply font-sans font-regular text-20 text-gray-900 overflow-x-hidden bg-white;
}

[x-cloak] {
    @apply hidden;
}

[disabled] {
    @apply opacity-50 cursor-not-allowed;
}

.disable-container {
    .container {
        width: 100% !important;
        max-width: none !important;
        padding: 0 !important;
    }
}

.calculator {
    a {
        text-decoration: underline;
    }
}