.loading-animation-1{
    animation: fadeIn 500ms ease-out both;
}

.loading-animation-2{
    animation: fadeIn 500ms ease-out both;
    animation-delay: 300ms;
}

.loading-animation-3{
    animation: fadeIn 500ms ease-out both;
    animation-delay: 600ms;
}

.loading-animation-4{
    animation: fadeIn 500ms ease-out both;
    animation-delay: 900ms;
}

@keyframes fadeIn {
    from{
        transform: translateY(20px);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

.loading-bar {
    position: relative;
    display: inline-block;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.25));
        animation: loading 1.5s infinite;
    }
}

@keyframes loading {
    0% {
        left: -100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}
