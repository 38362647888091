.ImageTeaserSlider {
    .Teaser {
        min-height: 470px;

        @media (min-width: theme("screens.md")) {
            min-height: 550px;
        }

        @media (min-width: theme("screens.lg")) {
            min-height: 700px;
        }
    }
}
