form,
.filter-group,
.form-group {
    label {
        @apply text-16 text-gray-700;

        &.required {
            &::after {
                @apply text-gray-500;
                content: attr(data-required);
            }
        }

        a {
            @apply text-gray-900;
            text-decoration: underline;
        }
    }

    textarea {
        @apply resize-none;
        min-height: 120px;
    }

    input,
    textarea,
    select {
        @apply px-5 py-3 bg-white leading-normal;

        &[readonly] {
            background: theme('colors.gray.50') !important;
            cursor: not-allowed;
        }
    }

    select {
        @apply appearance-none bg-no-repeat;
        background-position: right 1rem center;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE2IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMOC4wMDAzNSA3TDE1IDEiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xIDFMOC4wMDAzNSA3TDE1IDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
    }

    .radio,
    .checkbox {
        @apply relative pl-8;

        input {
            @apply hidden;
        }

        label {
            @apply text-16;
            cursor: pointer;

            &::before,
            &::after {
                @apply absolute left-0 block w-6 h-6;
                content: "";
            }

            &::before {
                @apply rounded bg-white border border-gray-900;
            }

            &::after {
                @apply top-0 ease-in-out duration-300 rounded border border-gray-900;
                background: theme('colors.white') url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='%23435056' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.8499 2.25012L6.24985 12.8501C6.15597 12.9448 6.02817 12.998 5.89485 12.998C5.76153 12.998 5.63374 12.9448 5.53985 12.8501L0.149852 7.46012C0.0551959 7.36623 0.00195312 7.23844 0.00195312 7.10512C0.00195312 6.9718 0.0551959 6.844 0.149852 6.75012L0.849852 6.05012C0.943735 5.95546 1.07153 5.90222 1.20485 5.90222C1.33817 5.90222 1.46597 5.95546 1.55985 6.05012L5.88985 10.3801L15.4399 0.830117C15.6377 0.638333 15.952 0.638333 16.1499 0.830117L16.8499 1.54012C16.9445 1.634 16.9978 1.7618 16.9978 1.89512C16.9978 2.02844 16.9445 2.15623 16.8499 2.25012Z' fill='%23435056'/%3E%3C/svg%3E%0A") center no-repeat;
                background-size: 65%;
                content: "";
                opacity: 0;
                visibility: hidden;
                transition-property: visibility, opacity;
            }
        }

        input:checked ~ label {
            &::after {
                opacity: 1;
                visibility: visible;
            }
        }

        &-custom {
            @apply text-16 ;
        }
    }

    .form-row {
        &.has-error {
            > label {
                color: theme('colors.violet');
            }

            input, select, textarea {
                border: 2px solid theme('colors.violet') !important;
            }

            .error-icon,
            .error-message {
                @apply block;
            }

            .checkbox,
            .radio {
                label {
                    @apply text-violet;

                    &::before {
                        border: 2px solid theme('colors.violet') !important;
                    }
                }
            }
        }

        .error-field {
            border: 2px solid theme('colors.violet') !important;
        }

        .error-message {
            @apply hidden text-12 mt-1;
            color: theme('colors.violet');
        }

        .error-icon {
            @apply hidden absolute text-violet top-3 right-5 w-6 h-6;
            background-size: contain;
        }
    }

    .theme-label {
        @apply text-16 text-gray-700 transform translate-y-4 md:translate-y-5 lg:translate-y-7 transition-all duration-500;

        &.has-value {
            @apply translate-y-1 md:translate-y-2 text-12 text-gray-700;
        }
    }

    .theme-border {
        @apply border-none rounded-sm;
    }

    .row-datetime {
        .theme-label {
            @apply translate-y-2 text-12 text-gray-700;
        }
    }
}

input[type="checkbox"] {
    &.checkmark {
        &:checked {
            background-color: theme('colors.white');
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
        }
    }
}