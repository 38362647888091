.Video {
    &.plyr__poster-enabled {
        .plyr__poster {
            background-position: top;
            background-size: cover;
        }
        .plyr__control--overlaid {
            @apply bg-blue;
        }
    }
}
