table{
    table-layout: fixed !important;
    width: 100% !important;

    td, th{
        @apply border border-gray-700 py-4 px-2 text-left;
    }

    th{
        @apply font-bold;
    }

    @media (max-width: theme("screens.md")) {
        @apply block;

        td {
            @apply block border-none px-0 py-0;
        }

        th {
            @apply hidden;
        }

        tr > td:nth-of-type(1){
            @apply font-bold mt-3;
        }
    }
}