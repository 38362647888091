.ServiceTeamGrid {
    .team-list {
        @apply grid gap-5 grid-cols-2 md:gap-8;

        @media (min-width: theme("screens.md")) {
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
            align-content: start;
        }

        @media (min-width: theme("screens.xl")) {
            grid-template-columns: repeat(auto-fit, 200px);
            justify-content: end;
        }
    }
}
