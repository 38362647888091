@font-face {
    font-family: "PT Sans";
    src: url("../fonts/ptsans-regular-webfont.woff2") format("woff2"),
        url("../fonts/ptsans-regular-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PT Sans";
    src: url("../fonts/ptsans-bold-webfont.woff2") format("woff2"),
        url("../fonts/ptsans-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
