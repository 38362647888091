.HeroContent {
    .image-container::after {
        content: "";

        @apply absolute left-0 top-0 w-full h-full -z-10;

        background: linear-gradient(
            144.63deg,
            #445157 15.45%,
            rgba(81, 101, 111, 0) 59.62%
        );
        transform: rotate(-180deg);

        @media (min-width: theme("screens.md")) {
            transform: rotate(0);
        }
    }
}
