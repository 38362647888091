.NewsTeaser{
    @media (min-width: theme("screens.lg")) {
        .main-grid{
            @apply grid;

            grid-template-columns: 80px 1fr;
        }

        .main-headline{
            transform: rotate(-90deg) translateX(calc(-100% - 5px));
        }
    }
    
    @media (min-width: theme("screens.xl")) {
        .item:nth-of-type(1){
            grid-column: 1 / 3;
            @apply max-w-md;

            .headline{
                @apply text-24;
            }

            .teaser{
                @apply text-20;
            }
        }
        
    }

}