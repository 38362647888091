body:not([data-editmode="true"]) {
    .Teaser {
        &.gradient-mask::after {
            content: "";

            @apply absolute left-0 top-0 w-full h-full -z-10 pointer-events-none;

            background: linear-gradient(
                144.63deg,
                #445157 22.26%,
                rgba(81, 101, 111, 0) 99.41%
            );

            @media (min-width: theme("screens.lg")) {
                background: linear-gradient(
                    144.63deg,
                    #445157 15.45%,
                    rgba(81, 101, 111, 0) 59.62%
                );
            }
        }
    }
}
