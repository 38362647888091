:root {
    --cc-primary-color: theme("colors.blue");
    --cc-secondary-color: theme("colors.yellow");
    --cc-text-color: theme("colors.gray.900");
    --cc-text-color-headline: theme("colors.gray.900");
    --cc-modal-box-background: theme("colors.white"); // background of content
    --cc-border-radius: theme("borderRadius.sm");
    --cc-border-color: theme("colors.gray.100");
    --cc-switch-bg-inactive: theme("colors.gray.300");
    --cc-background: rgba(21, 34, 41, 0.85); // background for modal, body-before-element
    --cc-btn-primary-hover-bg: #00c2e9;
    --cc-btn-secondary-hover-bg: #ff9900;
    --cc-btn-default-hover-bg: #b8c0c5;
}

@import "@AccWebsiteBundle/GdprConsent/GdprConsent";

.consent-wrapper {
    top: 15px !important;
    bottom: 15px !important;

    @media (min-height: 500px) {
        top: 10vh !important;
        max-height: 80vh !important;
        bottom: auto !important;
    }

    @media (max-width: 768px) {
        left: 50% !important;
        transform: translate(-50%, 0) !important;
    }
}

.GdprConsentBrick {
    @apply text-gray-100;

    .GdprToggle {
        @apply transition-colors duration-300 ease-in-out;
    }
}

.GdprConsentModal {
    .script-actions-btn {
        @apply transition-colors duration-300 ease-in-out;
    }

    .script-item-info {
        @apply text-16;
    }
}