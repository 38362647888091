.Accordion {
    .accordion-item {
        @apply list-none;

        .accordion-header {
            .accordion-indicator {
                @apply transition-all duration-700;

                transition-property: transform, color;
            }

            &.active {
                @apply font-bold;

                .accordion-indicator {
                    @apply rotate-180;

                    color: #1d1d1b;
                }
            }
        }

        .accordion-content {
            @apply transition-all duration-700 overflow-hidden;

            transition-property: height;
        }
    }
}
