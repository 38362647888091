.Footer {
    .footer-grid {
        @apply md:grid md:gap-x-7;

        @media (min-width: theme("screens.md")) {
            grid-template-columns: 3fr 2fr;
        }
    }

    *{
        --tw-space-x-reverse: 1 !important;
    }

    use[href="#acc-arrow-right"]{
        transform-origin: center;
        transform: rotate(0deg);
    }

    .ml-auto{
        margin-left: auto;
        margin-right: 0;
    }
}
