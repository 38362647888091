@tailwind base;
@tailwind components;
@tailwind utilities;

@import "import-fonts";
@import "base";
@import "loading-animation";
@import "hover-animation";
@import "table";
@import "form";
@import "alert";
@import "loader";
@import "alert";
@import "components";
@import "web2print";
@import "wysiwyg";
@import "container";
@import "swiper";
@import "gdpr-consent";
