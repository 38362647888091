.alert {
    @apply p-3 md:p-4 lg:p-5 rounded-md bg-gray-300 mb-4 md:mb-6 lg:mb-8;

    &-success {
        @apply bg-yellow text-white;
    }

    &-danger {
        @apply bg-violet text-white;
    }
}