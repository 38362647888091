.TitleText {
    .wysiwyg-container,
    .pimcore_wysiwyg {
        u {
            @apply underline underline-offset-4;
        }

        strong {
            @apply font-bold;
        }

        a {
            @apply underline underline-offset-4 text-blue;
        }
    }
}
