.ListElement {
    .list {
        li {
            @apply flex items-start md:items-baseline gap-x-6 md:gap-x-8;

            &::before {
                content: attr(data-count);

                @apply shrink-0 w-12 h-12 grid place-items-center font-bold rounded-full border-current;

                border-width: 3px;
            }
        }
    }
}
