.PharmacyFinder {
    .header {
        .checkbox {
            @apply hidden;

            & + label {
                @apply flex gap-x-7 items-start;

                &::before {
                    content: "";

                    @apply shrink-0 w-5 h-5 border border-current block translate-y-2;
                }
            }

            &:checked {
                & + label::before {
                    background: url("../images/checked.svg");
                    background-size: 1rem 1rem;
                }
            }
        }

        input:not([disabled]),
        button:not([disabled]) {
            @apply transition-opacity duration-300;
        }

        input[disabled],
        button[disabled] {
            @apply opacity-30;
        }
    }

    .list {
        .info-container {
            @apply transition-all;

            transition-property: height;
        }

        .info-toggle {
            svg {
                @apply transition-transform;
            }

            &.expanded {
                svg {
                    @apply -rotate-90 lg:rotate-90;
                }
            }
        }
    }

    @media (min-width: theme("screens.lg")) {
        .header,
        .list {
            @apply relative isolate;

            &::after {
                content: "";

                @apply absolute right-0 top-0 h-full bg-blue -z-10;

                left: calc(50% - 50vw);
            }
        }

        .list::after {
            @apply bg-white;
        }
    }

    .map-container {
        .gmnoprint,
        .gm-fullscreen-control,
        .gm-svpc,
        img[alt="Google"] {
            @apply hidden;
        }
    }

    .shadow-top {
        box-shadow: 0px -30px 20px -25px #00000040;

        @media (min-width: theme("screens.lg")) {
            box-shadow: 0px -30px 20px -30px #00000040;
        }
    }
}
