.TeaserGrid {
    .headline strong {
        @apply font-regular text-yellow;
    }

    @media (min-width: theme("screens.md")) {
        .cards-container {
            @apply grid grid-cols-2 gap-x-7 gap-y-12;

            &:not(.two-items){
                & > *:nth-of-type(1) {
                    grid-column: 1 / -1;
                }
            }
        }
    }

    .Teaser {
        min-height: 500px;
    }
}
