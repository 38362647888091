.HeroHome {
    .image-container {
        @media (min-width: theme("screens.lg")) {
            height: 450px;
        }

        @media (min-width: theme("screens.xl")) {
            height: 500px;
        }
    }
}
