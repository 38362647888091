.Header {
    nav {
        height: calc(100vh - 100%);

        //hide initially
        @apply pointer-events-none transition-all duration-500 ease-in-out;

        transition-property: clip-path;

        clip-path: polygon(
            0 0,
            100% 0,
            100% 0,
            0 0
        );

        &.open{
            @apply pointer-events-auto;

            clip-path: polygon(
                0 0,
                100% 0,
                100% 100%,
                0 100%
            );
        }

        @media (min-width: theme("screens.xl")) {
            pointer-events: auto !important;

            clip-path: none;
        }

        .nav-button {
            @apply transition-colors;

            &[aria-expanded="true"] {
                @apply font-bold text-yellow;
            }

            & + .column-container {
                @apply transition-all overflow-hidden duration-700;

                transition-property: height;
            }

            &[aria-expanded="false"] {
                & + .column-container {
                    height: 0;
                }
            }
        }

        & > *.nav-animation-fade{
            @apply transition-all duration-300 ease-out translate-y-5 xl:translate-y-0 opacity-0 xl:opacity-100;

            @for $i from 1 through 15 {
                &:nth-of-type(#{$i}) {
                  transition-delay: #{(100 * ($i - 1))}ms;
                }
              }
        }

        &.open{
            .nav-animation-fade{
                @apply opacity-100 translate-y-0;
            }
        }
    }

    #toggle-nav{
        *{
            @apply transition-all duration-300 ease-in-out;
        }
        
        &.open{
            & > *:nth-of-type(1){
                @apply rotate-45;
            }

            & > *:nth-of-type(2),
            & > *:nth-of-type(3){
                @apply scale-x-0 opacity-0;
            }

            & > *:nth-of-type(4){
                @apply -rotate-45 -translate-y-0.5;
            }
        }
    }
}
