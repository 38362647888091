.Web2Print-ProductList {
    background-color: #ffffff !important;

    // padding cant be defined on the whole page
    // We have to define it on every single element...

    .padding-x {
        @apply px-14;
    }

    .padding-top {
        @apply pt-14;
    }

    .padding-bottom {
        @apply pb-14;
    }

    // default tailwind color classes don't work -> they produce rgb()-colors
    .text-white {
        color: white !important;
    }

    .text-black {
        color: #000000 !important;
    }

    .bg-blue {
        background-color: #009bb9 !important;
    }

    .bg-blue-low-opacity {
        background-color: #e6f5f8 !important;
    }

    // Custom text sizes
    // Size in class name comes from figma

    .text-10{
      font-size: 14px;
      line-height: 1.2;
    }

    .text-8{
      font-size: 12px;
      line-height: 1.5;
    }

    .text-12{
      font-size: 18px;
      line-height: 1.2;
    }

    // border-property does really strange things. We have to use pseudo elements with background color...
    .border-top-black {
        position: relative;

        &::before{
          content: "";

          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 1px;
          background-color: #000000;
        }

        &::after{
          content: "";

          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          background-color: #000000;
        }
    }

    .main-grid {
        display: flex !important;

        .grid-item-1 {
            width: 7.5% !important;
            display: block !important;
        }
        .grid-item-2 {
            width: 46.25% !important;
            display: block !important;
        }
        .grid-item-3 {
            width: 46.25% !important;
            display: block !important;
        }
    }

    .list-item{
      page-break-inside: avoid;

      &.page-margin{
        padding-top: 100px;
      }
    }
}

