.arrow-link-hover {
    svg {
        @apply transition-transform duration-500;
    }

    &:hover,
    &:focus {
        svg {
            @apply translate-x-2;
        }
    }
    
    &-reverse{
        svg {
            @apply transition-transform duration-500;
        }

        &:hover,
        &:focus {
            svg {
                @apply -translate-x-2;
            }
        }
    }
}

[dir="rtl"] .arrow-link-hover:hover svg {
    @apply -translate-x-2;
}

[dir="rtl"] .arrow-link-hover-reverse:hover svg {
    @apply translate-x-2;
}

body[dir="rtl"]{

}

.link-box-hover {
    @apply relative;

    &::after {
        content: "";

        @apply bg-current absolute left-0 top-0 w-1 h-full pointer-events-none scale-x-0 origin-left transition-transform duration-300;
    }

    & > span {
        @apply transition-transform duration-300;
    }

    &:hover,
    &:focus {
        &::after {
            @apply scale-x-100;
        }

        & > span {
            @apply translate-x-1;
        }
    }
}

.underline-hover {
    @apply relative w-fit;

    &::after {
        content: "";

        @apply bg-current absolute left-0 w-full top-full h-0.5 scale-x-0 origin-right transition-transform duration-500;
    }

    &:hover,
    &:focus {
        &::after {
            @apply scale-x-100 origin-left;
        }
    }
}
